<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.warehouse_code')"
                :placeholder="$t('labels.warehouse_code')"
                name="code_name"
                sort-name="code_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.model')"
                :placeholder="$t('labels.model')"
                name="model"
                sort-name="model"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.hotline')"
                :placeholder="$t('labels.hotline')"
                name="hotline"
                sort-name="hotline"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.email')"
                :placeholder="$t('labels.email')"
                name="email"
                sort-name="email"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectCountry
                class="c-input-xs"
                name="id_country"
                :label="$t('labels.country')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectCity
                class="c-input-xs"
                name="id_city"
                :id-country="filters.id_country"
                :label="$t('labels.city')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectCounty
                class="c-input-xs"
                name="id_county"
                :id-city="filters.id_city"
                :label="$t('labels.county')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectWard
                class="c-input-xs"
                name="id_ward"
                :id-county="filters.id_county"
                :id-city="filters.id_city"
                :label="$t('labels.ward')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.address')"
                :placeholder="$t('labels.address')"
                name="address"
                sort-name="address"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.latitude')"
                :placeholder="$t('labels.latitude')"
                name="latitude"
                sort-name="latitude"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.longitude')"
                :placeholder="$t('labels.longitude')"
                name="longitude"
                sort-name="longitude"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.max_order_confirm')"
                :placeholder="$t('labels.max_order_confirm')"
                name="max_order_confirm"
                sort-name="max_order_confirm"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.type')"
                :placeholder="$t('labels.type')"
                name="type"
                sort-name="type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.cutoff')"
                :placeholder="$t('labels.cutoff')"
                name="is_pickup_cutoff"
                sort-name="is_pickup_cutoff"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.biggest_cell_first_line')"
                :placeholder="$t('labels.biggest_cell_first_line')"
                name="biggest_cell_first_line"
                sort-name="biggest_cell_first_line"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="active"
                sort-name="active"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn
                color="success"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td>
              <span v-if="!item.editing">{{ item.code_name }}</span>
              <v-text-field
                v-else
                v-model="item.code_name"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.model }}</span>
              <v-autocomplete
                v-else
                v-model="item.model"
                :items="warehouseModels"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.hotline }}</span>
              <v-text-field
                v-else
                v-model="item.hotline"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.email }}</span>
              <v-text-field
                v-else
                v-model="item.email"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.country_name }}
              </span>
              <SelectCountry
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-country="item.id_country"
                :label="$t('labels.country')"
                name="id_country"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.city_name }}
              </span>
              <SelectCity
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-country="item.id_country"
                :id-city="item.id_city"
                :label="$t('labels.city')"
                name="id_city"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.county_name }}
              </span>
              <SelectCounty
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :id-county="item.id_county"
                :label="$t('labels.county')"
                name="id_county"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.ward_name }}
              </span>
              <SelectWard
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :id-county="item.id_county"
                :id-ward="item.id_wards"
                :label="$t('labels.ward')"
                name="id_wards"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing">{{ item.address }}</span>
              <v-text-field
                v-else
                v-model="item.address"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.latitude }}</span>
              <v-text-field
                v-else
                v-model.number="item.latitude"
                :disabled="!item.editing"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.longitude }}</span>
              <v-text-field
                v-else
                v-model.number="item.longitude"
                :disabled="!item.editing"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ formatNumber(item.max_order_confirm) }}
              </span>
              <vuetify-money
                v-else
                v-model.number="item.max_order_confirm"
                type="number"
                :options="globalMoneyOptions"
                class="c-input-small"
                outlined
                dense
                hide-details
                :disabled="!item.editing"
              />
            </td>
            <td>
              <span v-if="!item.editing">
                {{ item.type == 2 ? "DC" : "TMĐT" }}
              </span>
              <v-autocomplete
                v-else
                v-model="item.type"
                :disabled="!item.editing"
                :items="typeOptions"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.is_pickup_cutoff"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.biggest_cell_first_line"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.active"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                  style="width: 70px"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <br />
                <v-btn
                  v-if="!item.editing && item.id"
                  x-small
                  color="primary"
                  outlined
                  class="mt-1"
                  @click="showCreateBasketDialog(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.basket_create") }}
                </v-btn>
                <br />
                <v-btn
                  v-if="!item.editing && item.id"
                  x-small
                  color="purple"
                  outlined
                  class="mt-1"
                  @click="showWorkingTimeDialog(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.working_time") }}
                </v-btn>
                <br />
                <v-btn
                  v-if="!item.editing && item.id"
                  x-small
                  color="error"
                  outlined
                  class="mt-1"
                  @click="showDeliveryCostDialog(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.delivery_cost") }}
                </v-btn>
                <br />
                <v-btn
                  v-if="!item.editing && item.id"
                  x-small
                  color="success"
                  outlined
                  class="mt-1"
                  @click="showCreateLocationDialog(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.position") }}
                </v-btn>
                <br />
                <!-- <v-btn v-if="!item.editing && item.id" x-small color="warning" outlined class="mt-1"
                  @click="showLicenseDialog(item)" style="width: 70px">
                  {{ $t('labels.license') }}
                </v-btn> -->
                <v-btn
                  v-if="!item.editing && item.id"
                  x-small
                  color="info"
                  outlined
                  class="mt-1"
                  @click="showPickupDialog(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.config") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                  style="width: 70px"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                  style="width: 70px"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>

    <v-dialog v-model="dialogCreateBasket" persistent max-width="360px">
      <CreateBasket
        v-if="dialogCreateBasket"
        :updating-item="updatingItem"
        @cancel="hideCreateBasketDialog"
      />
    </v-dialog>

    <v-dialog v-model="dialogDeliveryCost" persistent max-width="360px">
      <CloneDeliveryCost
        v-if="dialogDeliveryCost"
        :updating-item="updatingItem"
        @cancel="hideDeliveryCostDialog"
      />
    </v-dialog>

    <v-dialog v-model="dialogWorkingTime" persistent max-width="1366px">
      <WorkingTime
        v-if="dialogWorkingTime"
        :updating-item="updatingItem"
        @cancel="hideWorkingTimeDialog"
      />
    </v-dialog>

    <v-dialog v-model="dialogCreateLocation" persistent max-width="1366px">
      <CreateWarehouseLocation
        v-if="dialogCreateLocation"
        :updating-item="updatingItem"
        @close="hideCreateLocationDialog"
      />
    </v-dialog>

    <v-dialog v-model="dialogLicense" persistent max-width="960px">
      <License
        v-if="dialogLicense"
        :updating-item="updatingItem"
        @cancel="hideLicenseDialog"
      />
    </v-dialog>

    <v-dialog v-model="dialogPickup" persistent max-width="1024px">
      <PickupConfig
        v-if="dialogPickup"
        :updating-item="updatingItem"
        @refreshData="getList"
        @cancel="hidePickupDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "Create",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectCountry: () => import("@/components/common/SelectCountry"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    CreateBasket: () => import("@/components/warehouse/CreateBasket"),
    WorkingTime: () => import("@/components/warehouse/WorkingTime"),
    CloneDeliveryCost: () => import("@/components/warehouse/CloneDeliveryCost"),
    CreateWarehouseLocation: () =>
      import("@/components/warehouse/CreateWarehouseLocation"),
    License: () => import("@/components/warehouse/License"),
    PickupConfig: () => import("@/components/warehouse/PickupConfig"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    warehouse: {},
    detailDialog: false,
    isLoading: false,
    statusOptions: [...YES_NO_OPTIONS],
    warehouseModels: [
      "Fulfilment by SSC",
      "Operation as a Services",
      "Software as a Services",
      "Operation Transfer",
      "Collaborate",
      "Not Exist",
    ],
    updatingItem: {},
    dialogCreateBasket: false,
    dialogWorkingTime: false,
    dialogDeliveryCost: false,
    dialogCreateLocation: false,
    dialogLicense: false,
    dialogPickup: false,
    dialogConfirmOrder: false,
    cutoffByOptions: [
      {
        text: "Tạo đơn trên SSC",
        value: "gi.create_timestamp",
      },
      {
        text: "Đủ điều kiện xuất kho",
        value: "gid.receipt_at",
      },
      {
        text: "Tạo đơn (Theo sàn nếu có)",
        value: "gid.created_at",
      },
    ],
    typeOptions: [
      {
        text: "TMĐT",
        value: 1,
      },
      {
        text: "DC",
        value: 2,
      },
    ],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        code_name: null,
        model: null,
        latitude: null,
        longitude: null,
        address: null,
        id_city: null,
        id_county: null,
        id_wards: null,
        hotline: null,
        biggest_cell_first_line: 0,
        is_pickup_cutoff: 1,
        active: 1,
        editing: true,
      });
    },
    onItemChange(val) {
      const items = [...this.items];
      let item = [...items].find((i) => i.id === val.id);
      if (item) {
        const itemIndex = [...items].findIndex((i) => i.id === val.id);
        item = { ...item, [val.name]: val.value };
        items[itemIndex] = item;
        this.items = [...items];
      }
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse/v1/save", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/warehouse/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showCreateBasketDialog(item) {
      this.updatingItem = { ...item };
      this.dialogCreateBasket = true;
    },
    hideCreateBasketDialog() {
      this.updatingItem = {};
      this.dialogCreateBasket = false;
    },
    showWorkingTimeDialog(item) {
      this.updatingItem = { ...item };
      this.dialogWorkingTime = true;
    },
    hideWorkingTimeDialog() {
      this.updatingItem = {};
      this.dialogWorkingTime = false;
    },
    showDeliveryCostDialog(item) {
      this.updatingItem = { ...item };
      this.dialogDeliveryCost = true;
    },
    hideDeliveryCostDialog() {
      this.updatingItem = {};
      this.dialogDeliveryCost = false;
    },
    showCreateLocationDialog(item) {
      this.updatingItem = { ...item };
      this.dialogCreateLocation = true;
    },
    hideCreateLocationDialog() {
      this.updatingItem = {};
      this.dialogCreateLocation = false;
    },
    showLicenseDialog(item) {
      this.updatingItem = { ...item };
      console.log(this.updatingItem);
      this.dialogLicense = true;
    },
    hideLicenseDialog() {
      this.updatingItem = {};
      this.dialogLicense = false;
    },
    showPickupDialog(item) {
      this.updatingItem = { ...item };
      this.dialogPickup = true;
    },
    hidePickupDialog() {
      this.updatingItem = {};
      this.dialogPickup = false;
    },
    showConfirmOrderDialog(item) {
      this.updatingItem = { ...item };
      this.dialogConfirmOrder = true;
    },
    hideConfirmOrderDialog() {
      this.updatingItem = {};
      this.dialogConfirmOrder = false;
    },
  },
};
</script>

<style scoped></style>
